<template>
  <div id="upLoadFile">
    <div class="title" v-if="id">
      <p class="head">编辑作品</p>
      <p class="foot">已上传作品仅支持以下内容修改！</p>
    </div>
    <div class="title" v-else>
      <p class="head">上传作品</p>
      <p class="foot">参赛作品不支持删除，大赛进行中上传后的作品如需修改，可在【个人中心-作品】点击“编辑”进行修改。</p>
    </div>
    <div class="form">
      <el-form label-position="right" label-width="76px" :model="formData" :rules="rules" ref="uploadForm">
        <el-form-item label="*作品名称" prop="name">
          <el-input v-model="formData.name" placeholder="仅限中英文，10个字以内" class="input-item"></el-input>
        </el-form-item>
        <div class="type_wrap" v-if="!id">
          <el-form-item label="*参赛单元" prop="competitionUnit">
            <el-select v-model="formData.competitionUnit" placeholder="请选择" class="works-type">
              <el-option v-for="item in unitList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="*作品类别" prop="category">
            <el-select v-model="formData.category" placeholder="请选择" class="works-type" @change="cateChange">
              <el-option v-for="item in categoryList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form label-position="top">
          <el-form-item label="*上传作品" prop="contents">
            <template v-if="formData.category === 1 || formData.category === 3">
              <div>
                <el-upload class="myfile2" list-type="picture-card" :before-upload="beforeAvatarUpload" :file-list="formData.contents.filter((i) => i.status === 'success')
                  " accept="image/gif,image/jpeg,image/x-png" :limit="5" action :http-request="handleUploadFn">
                  <img class="upload-icon" src="@/assets/img/upload-icon.png" alt="">
                  <p class="upload-type">上传参赛作品</p>
                  <p class="file-limit-tip">支持JPG/PNG/GIF格式 <br> 最多上传5张，每张不超过20M</p>
                  <template #file="{ file }">
                    <div>
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file, 2)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </template>
                </el-upload>
                <el-dialog v-model="contentDialog">
                  <img style="width: 100%" :src="contentUrl" alt="" />
                </el-dialog>
              </div>
            </template>
            <template v-else>
              <div>
                <el-upload class="myfile2 videofile" accept="video/mp4" action :http-request="handleVideoFn"
                  :before-upload="ElfileUpload" :file-list="formData.contents.filter((i) => i.status === 'success')
                    ">
                  <img class="upload-icon" src="@/assets/img/upload-icon.png" alt="">
                  <p class="upload-type">上传参赛作品</p>
                  <p class="file-limit-tip">支持MP4格式<br>1920*1080像素</p>
                </el-upload>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="*上传封面" prop="cover">
            <div class="myuploadPreview">
              <el-upload ref="coverFile" :key="coverKey" class="avatar-uploader myfile1"
                :before-upload="beforeCoverUpload" list-type="picture-card" :limit="1"
                :file-list="formData.cover.filter((i) => i.status === 'success')"
                accept="image/gif,image/jpeg,image/x-png" :on-change="(file, fileList) => {
                  elUpload(file, fileList, 'coverFile');
                }
                  " :auto-upload="false">
                <img class="upload-icon" src="@/assets/img/upload-icon.png" alt="">
                <p class="upload-type">点击上传</p>
                <p class="file-limit-tip">支持JPG/PNG/GIF格式 <br> 大小不超过5M <br> 建议尺寸780×540px
                </p>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="*作品说明" prop="description">
            <el-input type="textarea" v-model="formData.description" maxlength="200" show-word-limit class="input-item"
              resize="none" placeholder="200个字以内">
            </el-input>
          </el-form-item>
        </el-form>
        <el-form-item label="推荐单位" prop="recommenderId" v-if="list && !id">
          <el-select v-model="formData.recommenderId" placeholder="请选择" class="works-type" :clearable="true"
            style="width: 180px; margin-right: 10px">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="*就读院校" prop="school" v-if="formData.competitionUnit == 2 && !id">
          <el-input v-model="formData.school" placeholder="仅限中英文，10个字以内" class="input-item"></el-input>
        </el-form-item>
        <el-form-item label="指导老师" prop="adviser" v-if="formData.competitionUnit == 2 && !id">
          <el-input v-model="formData.adviser" placeholder="仅限中英文，10个字以内" class="input-item"></el-input>
        </el-form-item>
        <el-form-item label="学生证明" prop="studentCard" v-show="formData.competitionUnit == 2 && !id">
          <div class="myuploadPreview">
            <el-upload ref="coverFile" class="avatar-uploader myfile3" :limit="1" list-type="picture-card" :on-change="(file, fileList) => {
              elUpload(file, fileList, 'studentFile');
            }
              " :auto-upload="false">
              <img class="upload-icon" src="@/assets/img/upload-icon.png" alt="">
              <p class="upload-type">上传学生证明</p>
              <p class="file-limit-tip">支持JPG/PNG格式<br>大小不超过5M</p>
              <template #file="{ file }">
                <div>
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file, 3)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </template>
            </el-upload>
            <div class="checkInstance">
              <span class="viewInstance" @click="viewInstance">*点击查看示意图</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="read-confirm" v-if="!id">
          <el-checkbox v-model="formData.checked"></el-checkbox>
          <span class="protocol">我已阅读<i @click="dialogVisible = true">《投稿须知》</i>，并同意所有条款</span>
        </el-form-item>
        <el-form-item class="confirm-btn">
          <el-button v-if="!id" type="primary" class="button-block" @click="formSubmitEvent">确认上传</el-button>
          <el-button v-else type="primary" class="button-block-edit" @click="formEditEvent">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tougaoxuzhi">
      <el-dialog v-model="dialogVisible">
      </el-dialog>
    </div>
    <el-dialog v-model="studentCardDialog">
      <img style="width: 100%" src="../assets/img/studentCard.png" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { create, listAll, getOssSignature, edit, downloadFileToServer } from "../utils/apis/fileApi";
import { getById } from "../utils/apis/worksApi.js";
import { upload } from "../utils/uploadSign";
import { ElMessage, ElLoading } from "element-plus";
export default {
  name: "upLoadFile",
  setup() {
    const id = ref(useRouter().currentRoute._rawValue.query.id)
    const coverUpload = ref(null);
    const uploadForm = ref(null);
    const coverFile = ref(null)
    const coverKey = ref(new Date().getTime())
    const contentDialog = ref(false);
    const dialogVisible = ref(false);
    const coverUrl = ref("");
    const contentUrl = ref("");
    const studentCardUrl = ref("");
    const studentCardDialog = ref(false);
    const showLoading = ref(false);

    const rules = reactive({
      name: [
        { required: true, message: "请输入作品名称", trigger: "blur" },
        {
          pattern: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
          message: "仅限中英文，10个字以内",
          trigger: "blur",
        },
      ], //作品名
      category: [
        { required: true, message: "请选择作品类别", trigger: "change" },
      ], //作品类别
      description: {
        required: true,
        message: "请输入作品介绍",
        trigger: "blur",
      }, //作品介绍
      competitionUnit: [
        { required: true, message: "请选择作品类别", trigger: "change" },
      ], //参赛单元
      contents: [{ required: true, message: "请上传作品", trigger: "blur" }], //作品内容列表，阿里云存储的路径列表。
      adviser: [
        { required: true, message: "请填写指导老师姓名", trigger: "blur" },
        {
          pattern: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
          message: "仅限中英文，10个字以内",
          trigger: "blur",
        },
      ],
      school: [
        { required: true, message: "请填写学校名称", trigger: "blur" },
        {
          pattern: /^[\u4e00-\u9fa5a-zA-Z]{1,10}$/,
          message: "仅限中英文，10个字以内",
          trigger: "blur",
        },
      ],
      cover: [{ required: true, message: "请上传作品封面", trigger: "blur" }], //作品封面
    });
    const formData = reactive({
      name: "", //作品名
      category: 1, //作品类别
      competitionUnit: 1, //参赛单元
      contentFiles: [], //作品内容列表，阿里云存储的路径列表。
      coverFile: [], //作品封面
      recommenderId: "", //推荐单位
      school: "", //学校
      studentCard: [], //学生证明
      studentFile: [],
      cover: [],
      contents: [],
      checked: false,
      description: "",
      adviser: "",
    });
    const categoryList = reactive([
      {
        name: "设计类",
        value: 1,
      },
      {
        name: "Ani动画类",
        value: 2,
      },
      {
        name: "CG类",
        value: 3,
      },
    ]);

    const unitList = reactive([
      {
        name: "专业组",
        value: 1,
      },
      {
        name: "高校组",
        value: 2,
      },
    ]);
    let list = ref([]);
    const mysign = reactive({
      data: null,
    });
    const recommendList = async () => {
      try {
        await listAll().then((res) => {
          list.value = res.data.data;
        });
      } catch (e) {
        console.log(e);
      }
    };
    function getDetail() {
      getById({ id: id.value }).then((res) => {
        if (res.data.code === 200) {
          formData.category = res.data.data.category
          formData.name = res.data.data.name
          formData.contents = res.data.data.contents.map(item => {
            let arr = item.split('')
            arr.splice(0, 47)
            return {
              name: arr.join(''),
              status: "success",
              url: item,
            }
          })
          console.log(formData.contents)
          formData.cover = [res.data.data.cover].map(item => {
            let arr = item.split('')
            arr.splice(0, 47)
            return {
              name: arr.join(''),
              status: "success",
              url: item,
            }
          })
          formData.description = res.data.data.description
        }
      });
    }
    recommendList();
    getSignature();
    if (id) {
      getDetail()
    }
    const router = useRouter();
    //按钮点击实现上传
    function fileUpload(typeName) {
      if (typeName === "coverFile") {
        document.querySelector(".myfile1 .el-upload__input").click();
      } else if (typeName === "contentFiles") {
        if (formData.category == "") {
          ElMessage({
            message: "请选择作品类别",
            type: "error",
            customClass: "myElMessage",
          });

          return;
        }
        document.querySelector(".myfile2 .el-upload__input").click();
      } else {
        document.querySelector(".myfile3 .el-upload__input").click();
      }
    }
    // let recommendList;

    //查看学生证明图片
    function viewInstance() {
      studentCardDialog.value = true;
    }

    //移除文件
    function onRemove(file, filelist, listName) {
      formData[listName] = filelist;
    }

    //本地上传
    function upLoadCover(event, typeName, isElUpload) {
      let file = null;
      if (typeName === "coverFile") {
        beforeCoverUpload(event).then((res) => {
          // if (typeName === "coverFile") {
          if (res) {
            if (typeName === "coverFile" || typeName === "studentFile") {
              if (isElUpload) {
                file = [event.raw];
              } else {
                file = event.target.files;
              }
              upLoadAli(file, typeName);
            }
          } else {
            coverKey.value = new Date().getTime()
            return;
          }
          // }
        });
      } else if (typeName === "contentFiles" && formData.category == 2) {
        beforeVideoUpload(event).then((res) => {
          if (res) {
            file = [event.raw];

            upLoadAli(file, typeName);
          }
        });
      } else {
        file = [event.raw];
        upLoadAli(file, typeName);
      }
    }

    function getSignature() {
      //获取签名
      getOssSignature().then((res) => {
        const sign = res.data.data;
        mysign.data = sign;
      });
    }

    function randomString(len) {
      len = len || 16;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
      let maxPos = chars.length;
      let str = "";
      for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return str; sign
    }

    function getSuffix(filename) {
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    }

    //上传阿里 附带签名
    function upLoadAli(file, typeName) {
      const loading = ElLoading.service({
        lock: true,
        text: '上传中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const params = new FormData();
      params.append(
        "key",
        mysign.data.dir +
        `${dateFormat("yyyy-MM-dd")}/` +
        `${randomString()}${getSuffix(file[0].name)}`
      );
      params.append("OSSAccessKeyId", mysign.data.accessKeyId);
      params.append("policy", mysign.data.policy);
      params.append("signature", mysign.data.signature);
      params.append("callback", mysign.data.callback);
      params.append("success_action_status", "200"); // 上传成功返回的状态码，不设置则是204
      params.append("file", file[0]); // 一定在最后
      console.log(file)
      if (mysign.data.expiration < Date.now() + 60 * 1000) {
        getSignature();
      }
      //视频

      if (typeName === "contentFiles" && formData.category == 2) {
        showLoading.value = true;
      }
      upload(params, mysign.data.host)
        .then((res) => {
          if (res.data.code === 200) {
            loading.close()
            let data = res.data.data;
            if (typeName === "coverFile") {
              if (formData.cover.length != 0) {
                formData.cover[0] = {
                  name: data.objectKey,
                  url: data.url,
                  status: "success",
                };
                formData[typeName][0] = file[0];
              } else {
                formData.cover.push({
                  name: data.objectKey,
                  url: data.url,
                  status: "success",
                }); //上传成功之后把值添加到imglist中

                formData[typeName].push(file[0]);
              }
              coverUrl.value = data.url;
            } else if (typeName === "contentFiles") {
              if (formData.category != 2 || formData.contents.length === 0) {
                formData.contents.push({
                  name: data.objectKey,
                  status: "success",
                  url: data.url,
                });
                formData[typeName].push(file[0]);
                if (formData.category == 2) {
                  showLoading.value = false;
                }
              } else {
                formData.contents[0] = {
                  name: data.objectKey,
                  status: "success",
                  url: data.url,
                };
                showLoading.value = false;
                formData[typeName][0] = file[0];
              }
            } else {
              if (formData.studentCard.length != 0) {
                formData.studentCard[0] = {
                  name: data.objectKey,
                  url: data.url,
                  status: "success",
                };
                formData[typeName][0] = file[0];
              } else {
                formData.studentCard.push({
                  name: data.objectKey,
                  url: data.url,
                  status: "success",
                }); //上传成功之后把值添加到imglist中

                formData[typeName].push(file[0]);
              }
              studentCardUrl.value = data.url;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //eleupload自定义上传
    function elUpload(file, fileList, typeName) {
      upLoadCover(file, typeName, true);
    }

    //查看大图
    function handlePictureCardPreview(file) {
      contentDialog.value = true;
      contentUrl.value = file.url;
    }

    //移除文件
    function handleRemove(file, type) {
      console.log(file, type)
      if (type === 1) {
        console.log(coverFile)
        let index = formData.cover.findIndex((item) => item.url === file.url);
        formData.cover.splice(index, 1);
      }
      if (type === 2) {
        console.log(formData.contents)
        let index = formData.contents.findIndex((item) => item.url === file.url);
        formData.contents.splice(index, 1);
      }
      if (type === 3) {
        let index = formData.studentCard.findIndex((item) => item.url === file.url);
        formData.studentCard.splice(index, 1);
      }
      // formData.contentFiles
    }

    //视频上传检测
    function beforeVideoUpload(file) {
      console.log(file);
      return new Promise((reslove) => {
        let flag = true;

        if (file.type != "video/mp4") {
          ElMessage({
            message: `${file.name}类型不符合规范`,
            type: "error",
            customClass: "myElMessage",
          });
          flag = false;
        }
        videoPromise(file).then((res) => {
          var video = res;

          var duration = video.duration; // 得到时长
          let height = video.videoHeight;
          let width = video.videoWidth;
          console.log(duration);

          if (height != 1080 && width != 1920) {
            ElMessage({
              message: `${file.name}尺寸为${width}× ${height},不符合规范`,
              type: "error",
              customClass: "myElMessage",
            });

            flag = false;
          }
          reslove(flag);
        });
      });
    }

    function ElfileUpload(file) {
      return new Promise((reslove, reject) => {
        beforeVideoUpload(file).then((res) => {
          if (res) {
            reslove(res);
          } else {
            reject(res);
          }
        });
      });
    }

    //视频检测
    function videoPromise(file) {
      return new Promise((reslove) => {
        // var myVideo = [];
        window.URL = window.URL || window.webkitURL;
        // myVideo.push(file);
        var video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          console.log(video.videoWidth, video.videoHeight);
          reslove(video);
        };
        video.src = URL.createObjectURL(file);
      });
    }

    function beforeContentImageUpload(file) {
      return new Promise((reslove) => {
        let flag = true;
        if (!judgeType(file.name)) {
          ElMessage({
            message: `${file.name}类型不符合规范`,
            type: "error",
            customClass: "myElMessage",
          });
          flag = false;
        } else if (!judgeWorkSize(file.size)) {
          ElMessage({
            message: `${file.name}大小为 ${(file.size / 1024 / 1024).toFixed(
              2
            )}MB,不符合规范`,
            type: "error",
            customClass: "myElMessage",
          });
          flag = false;
        }
        reslove(flag);
      });
    }

    //上传文件检测
    function beforeCoverUpload(file) {
      return new Promise((reslove) => {
        let flag = true;
        if (!judgeType(file.name)) {
          ElMessage({
            message: `${file.name}类型不符合规范`,
            type: "error",
            customClass: "myElMessage",
          });
          flag = false;
        }
        if (!judgeSize(file.size)) {
          ElMessage({
            message: `${file.name}大小为 ${(
              file.raw.size /
              1024 /
              1024
            ).toFixed(2)}MB,不符合规范`,
            type: "error",
            customClass: "myElMessage",
          });
          flag = false;
        }
        reslove(flag);
      });
    }

    //类型判断
    function judgeType(filename) {
      var type = filename.toLowerCase().substr(filename.lastIndexOf("."));
      return type === ".jpg" || type === ".png" || type === ".gif";
    }

    //大小判断
    function judgeSize(size) {
      return size <= 1024 * 1024 * 5;
    }

    function judgeWorkSize(size) {
      //小于20M
      return size <= 1024 * 1024 * 20;
    }

    //提交上传
    function formSubmitEvent() {
      console.log(formData)
      if (!formData.checked) {
        ElMessage({
          message: `请同意 《投稿须知》`,
          type: "error",
          customClass: "myElMessage",
        });

        return;
      }
      if (formData.cover.length === 0) {
        ElMessage({
          message: `封面没有上传`,
          type: "error",
          customClass: "myElMessage",
        });

        return;
      }
      if (formData.contents.length === 0) {
        ElMessage({
          message: `作品没有上传`,
          type: "error",
          customClass: "myElMessage",
        });

        return;
      }
      if (formData.competitionUnit == 2 && formData.studentCard.length === 0) {
        ElMessage({
          message: `学生证明没有上传`,
          type: "error",
          customClass: "myElMessage",
        });
        return;
      }
      uploadForm.value.validate(async (vaild) => {
        if (vaild) {
          let params = {
            name: formData.name,
            category: formData.category,
            competitionUnit: formData.competitionUnit,
            contents: (() => {
              return formData.contents.map((item) => item.name);
            })(),
            cover: formData.cover[0].name,
            recommenderId: formData.recommenderId,
            description: formData.description,
            school: formData.competitionUnit == 2 ? formData.school : "",
            studentCard:
              formData.competitionUnit == 2 ? formData.studentCard[0].name : "",
            adviser: formData.competitionUnit == 2 ? formData.adviser : "",
          };

          saveData(params);
        }
      });
    }
    //提交编辑
    function formEditEvent() {
      let editformData = {}
      editformData.name = formData.name
      editformData.id = id.value
      editformData.contents = formData.contents.map(item => {
        return item.name
      })
      editformData.cover = formData.cover[0].name
      editformData.description = formData.description
      edit(JSON.stringify(editformData)).then(res => {
        if (res.data.code === 200) {
          ElMessage({
            message: "修改成功！",
            type: "success",
            customClass: "myElMessage",
          });
          router.push('/myWorks')
        } else {
          ElMessage({
            message: `${res.data.msg}`,
            type: "error",
            customClass: "myElMessage",
          });
        }
      })
    }
    function cateChange() {
      formData.contents = [];
      formData.contentFiles = [];
    }

    function saveData(params) {
      create(params)
        .then((res) => {
          if (res.data.code == 200) {
            ElMessage({
              message: res.data.message,
              type: "success",
              customClass: "myElMessage",
            });

            router.push({ path: "/myWorks" });
          } else {
            ElMessage({
              message: res.data.message,
              type: "error",
              customClass: "myElMessage",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function beforeAvatarUpload(file) {
      return new Promise((reslove, reject) => {
        beforeContentImageUpload(file).then((res) => {
          if (res) {
            reslove(res);
          } else {
            reject(res);
          }
        });
      });
    }

    function handleUploadFn(option) {
      let file = [option.file];
      upLoadAli(file, "contentFiles");
    }

    function handleVideoFn(option) {
      let file = [option.file];
      const loading = ElLoading.service({
        lock: true,
        text: '上传中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      showLoading.value = true;
      const params = new FormData();
      params.append("file", file[0]); // 一定在最后
      console.log(file)
      downloadFileToServer(params).then(res => {
        if (res.data.code === 200) {
          loading.close()
          formData.contents[0] = {
            name: res.data.data,
            status: "success",
            url: res.data.data,
          };
          formData["contentFiles"][0] = res.data.data;
        }
      })
      // upLoadAli(file, "contentFiles");
    }

    function dateFormat(fmt) {
      let getDate = new Date();
      let o = {
        "M+": getDate.getMonth() + 1,
        "d+": getDate.getDate(),
        "h+": getDate.getHours(),
        "m+": getDate.getMinutes(),
        "s+": getDate.getSeconds(),
        "q+": Math.floor((getDate.getMonth() + 3) / 3),
        S: getDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      if (fmt.includes("NaN")) {
        return "请刷新页面后重试";
      }
      return fmt;
    }

    return {
      id,
      rules,
      coverFile,
      formData,
      upLoadCover,
      categoryList,
      unitList,
      coverUpload,
      contentDialog,
      coverUrl,
      contentUrl,
      coverKey,
      onRemove,
      fileUpload,
      elUpload,
      cateChange,
      handlePictureCardPreview,
      handleRemove,
      studentCardDialog,
      studentCardUrl,
      viewInstance,
      formSubmitEvent,
      uploadForm,
      saveData,
      beforeCoverUpload,
      // uploadAli,
      recommendList,
      list,
      beforeAvatarUpload,
      handleUploadFn,
      handleVideoFn,
      ElfileUpload,
      showLoading,
      dialogVisible,
      formEditEvent
    };
  },
};
</script>

<style lang="less" scoped>
#upLoadFile {
  width: 850px;
  border-radius: 6px;
  background-color: #F7F7F7;
  padding-top: 70px;
  padding-left: 40px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 75px;

  .title {
    width: 850px;
    margin: 0 auto;
    margin-bottom: 34px;

    .head {
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      color: #222222;
      margin-bottom: 14px;
    }

    .foot {
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #555555;
    }
  }

  .form {
    margin: 0 auto;
    padding-bottom: 50px;


    .type_wrap {
      display: flex;
      justify-content: left;

      ::v-deep .el-form-item {
        margin-right: 40px;
      }
    }

    .el-form-item__label {
      font-size: 14px;
      color: #303133;
    }
  }
}

.videofile {
  width: 224px;

  ::v-deep .el-upload {
    position: relative;
    width: 224px;
    height: 170px;
    background-color: #fff;
    border: 1px dashed #cccccc;
    border-radius: 4px;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
  }
}

.checkInstance {
  width: 100%;
  height: 21px;
  line-height: 21px;
  font-size: 14px;
  color: #325888;
  margin-top: 11px;

  &>span {
    margin-left: 54px;
    border-bottom: 1.5px solid #325888;
  }
}

.myfile3,
.myfile2,
.myfile1 {
  .upload-icon {
    width: 25px;
    height: 24px;
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);
  }

  .upload-type {
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
    line-height: 20px;
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
  }

  .file-limit-tip {
    width: 100%;
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Normal;
    font-weight: normal;
    text-align: center;
    color: #999999;
    line-height: 16px;
    position: absolute;
    top: 88px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.upload-content {
  ::v-deep img {
    object-fit: cover;
  }
}

.upload-tip {
  margin: 8px 0;
  line-height: 14px;
  font-size: 14px;
  color: #c0c4cc;
  height: 14px;
}

.works-type {
  width: 180px;
}

.input-item {
  width: 400px;
}

::v-deep .el-input--suffix .el-input__inner::placeholder {
  color: #c0c4cc;
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

::v-deep .el-upload-list--picture .el-upload-list__item-status-label {
  background: #303133;
}

::v-deep .el-upload-list--picture .el-upload-list__item {
  height: 95px;
  padding-top: 12.5px;
}

.protocol {
  vertical-align: -1px;
  color: #909399;
  margin-left: 10px;

  i {
    font-style: normal;
    cursor: pointer;
    transition: 0.2s;
  }

  i:hover {
    color: #003588;
  }
}

.button-block-edit {
  margin-top: 36px;
  width: 152px;
  height: 40px;
  border-radius: 4px;
}

.button-block {
  height: 40px;
  width: 400px;
}

::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
  display: none;
}


::v-deep .el-textarea__inner {
  height: 120px;
}

.avatar {
  width: 224px;
  height: 170px;
  display: block;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 224px;
  height: 170px;
  line-height: 170px;
}

::v-deep .el-upload--picture-card {
  width: 224px;
  height: 170px;
  line-height: 170px;
  position: relative;
  background-color: #fff;
}



.tipIcon {
  font-size: 25px;
  color: dcdfe6;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.viewInstance {
  vertical-align: middle;
  cursor: pointer;
}

.viewInstance:hover {
  color: #003588;
}

.mytip {
  line-height: 24px;
}

::v-deep {
  .el-upload-list__item {
    transition: none !important;
  }
}

.title::after {
  display: block;
  content: "";
}

.read-confirm {
  margin-top: 51px;
}

.read-confirm,
.confirm-btn {

  ::v-deep .el-form-item__content {
    margin: 0 auto !important;
    text-align: center;
  }
}

.tougaoxuzhi/deep/ .el-dialog {
  // height:5px;
  height: 744px;
  width: 900px !important;
  background-image: url("../assets/img/tougaoxuzhi.png");
  background-size: contain;
  background-repeat: no-repeat;
  // background-position:center;
}
</style>
